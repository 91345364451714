import { BrowserCacheLocation } from '@azure/msal-browser';
import { Environment } from './model/Environment';
declare function require(moduleName: string): any;

export const environment: Environment = {
    production: false,
    target: 'PROD',
    useMock: false,
    version: require('../../package.json').version,
    webRoot: 'https://admin.mygphc.org',
    maintenanceAPIURL:  'https://webapp-t1prd-maintapi-4874.azurewebsites.net',
    msal: {
        auth: {
        clientId: 'bfb3b20a-6824-400e-b3a2-ab876d86d866',
        authority: 'https://login.microsoftonline.com/42db843f-8f58-4b3c-8a85-a80d906ff0f2',
        redirectUri: 'https://admin.mygphc.org',
        postLogoutRedirectUri: 'https://admin.mygphc.org',
        scope: 'https://pharmacyregulation.onmicrosoft.com/t1prdMyGPhCAdminApi/user_impersonation'
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage
        }    
      },
    api: {
        root: 'https://adminapi.mygphc.org'
    }
};
